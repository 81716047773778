import React, {Component} from 'react';
import MdContent from "../../MdContent";
import {getIntroductoryCourse} from "../../../utils/urls";

class IntroductoryCourse extends Component {
    render() {
        return (
            <MdContent mdUrl={getIntroductoryCourse}/>
        );
    }
}

export default IntroductoryCourse;