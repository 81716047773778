import React, {Component} from 'react';
import ServerMonitor from "./ServerMonitor";
import {Layout, Menu, Spin} from "antd";
import Sider from "antd/es/layout/Sider";
import {Content} from "antd/es/layout/layout";
import {getAllServerInfos} from "../../utils/urls";
import BillMarket from "./BillMarket";
import CommandControl from "./CommandControl";
import GridAsset from "./AssetList/GridAsset";
import StorageAsset from "./AssetList/StorageAsset";
import GridMeasureProfile from "./GridMeasureProfile";
import OwnerShipLog from "./GridOwnerShipLog";
import {Route, Switch} from "react-router-dom";
import {Redirect, withRouter} from "react-router";

class ServerControl extends Component {

    state = {
        collapsed: false,
        serverInfos: [],
    }

    componentDidMount() {
        this.flush()
    }

    flush = () => {
        fetch(getAllServerInfos, {
            method: "GET"
        }).then(res => res.json()).then(resp => {
            if (resp.code === 200) {
                this.setState({
                    serverInfos: resp.data.serverInfos,
                    content: <ServerMonitor serverInfos={resp.data.serverInfos}/>
                })
            }
        })
    }
    getItem = (label, key, icon, children, type) => {
        return {
            key,
            icon,
            children,
            label,
            type,
        };

    }
    menuClick = (item) => {
        const {serverInfos} = this.state
        let key = item.key
        this.props.history.push(`${this.props.match.url}/${key}`)

    }

    render() {
        const {collapsed, serverInfos} = this.state
        const {match} = this.props
        const path = match.path
        const menus = [
            this.getItem("服务器监控", 'ServerMonitor'),
            this.getItem("网格耗时监测", 'GridMeasureProfile'),
            this.getItem("网格破坏日志", 'OwnerShipLog'),
            this.getItem("市场信息", 'BillMarket'),
            // this.getItem("控制台", 'CommandControl'),
            this.getItem("游戏资产查询", 'GameAssets',null,
                [
                    this.getItem('网格信息','GridAsset'),
                    this.getItem('仓库信息','StorageAsset')
                ]),


        ]
        if (serverInfos.length === 0) {
            return (
                <div>
                    <Layout
                        style={{minHeight: '100vh'}}>
                        <Content style={{paddingLeft: "3rem"}}>
                            <Spin style={{minHeight: '100vh', margin: '5rem auto'}} tip="等待服务器数据中..."/>
                        </Content>
                    </Layout>
                </div>
            )
        }
        return (
            <div>
                <Layout
                    style={{minHeight: '100vh'}}>
                    <Sider collapsible
                           collapsed={collapsed}
                           onCollapse={value => this.setState({collapsed: value})}
                           breakpoint="lg"
                           collapsedWidth="0"
                    >
                        <Menu theme="dark" defaultSelectedKeys={['ServerMonitor']} onClick={this.menuClick}
                              mode="inline" items={menus}/>
                    </Sider>
                    <Content style={{paddingLeft: "3rem"}}>
                      <Switch>
                          <Route path={`${path}/ServerMonitor`}><ServerMonitor serverInfos={serverInfos}/></Route>
                          <Route path={`${path}/GridMeasureProfile`}><GridMeasureProfile serverInfos={serverInfos}/></Route>
                          <Route path={`${path}/BillMarket`}><BillMarket serverInfos={serverInfos}/></Route>
                          <Route path={`${path}/CommandControl`}><CommandControl serverInfos={serverInfos}/></Route>
                          <Route path={`${path}/GridAsset`}><GridAsset serverInfos={serverInfos}/></Route>
                          <Route path={`${path}/StorageAsset`}><StorageAsset serverInfos={serverInfos}/></Route>
                          <Route path={`${path}/OwnerShipLog`}><OwnerShipLog serverInfos={serverInfos}/></Route>
                          <Route path={`${path}/ServerMonitor`}><ServerMonitor serverInfos={serverInfos}/></Route>
                          <Route path={`*`}><Redirect to={`${path}/ServerMonitor`}/></Route>
                      </Switch>
                    </Content>
                </Layout>
            </div>
        );
    }
}

export default withRouter(ServerControl)