import React, {Component} from 'react';
import '../ruleContent.css'

class GridsRule extends Component {
    render() {
        return (
            <div className={'textContent'}>
                <br/>
                服务器每一段时间会统计一次网格性能开销耗时。根据耗时进行惩罚和限制<br/>
                每个玩家拥有一个豁免额度，在线玩家0.5ms，离线玩家0.3ms，精英工程师豁免额度翻倍<br/>
                如果一个统计周期中，玩家的所有网格总耗时超过了豁免额度，将会扣除<strong>今日耗时额度</strong><br/>
                玩家每天拥有2ms耗时额度，扣光后，每次触发超时将会把那名玩家的一个随机船只收进船坞进行封存。<br/>
                除了每天刷新耗时额度，服务器每次重启也会给所有玩家发放一个少量的耗时额度，但是不超过每日初始额度上限<br/>
                <strong>
                    除此之外，服务器保留在卡服状态下紧急处置船只的权利，请玩家自觉不要卡服！！！
                </strong>

                <h1 style={{color: "red"}}>请注意：使用多个连接器/起落架连接的网格连接体，被视为一个网格。执行单网格限额</h1>
                <h1>银河帝国服务器 空间站/船只限制：</h1>
                <div className={'textContent'}>
                    <h2> pcu空间站限制5w，船只限制3w。网格超过5万pcu收进船坞后将禁止取出。需提交工单并给出正当理由。</h2>
                    <br/>
                    <h3>单网格：1/2服方块上限3500,海盗空间方块上限5000</h3>
                    精炼厂类/装配机类各10个（每玩家且每网格） <br/>
                    超算机 2个 （每玩家）<br/>
                    大型护盾发生器 4个（每网格） <br/>
                    反应堆上限16个（甲烷+柴油+核+注电器+反物质，合并计算）（每网格） <br/>
                    电池上限16个（每网格） <br/>
                    推进器上限64 （每网格）<br/>
                    陀螺仪上限24 （每网格）<br/>
                    气罐上限16个 （每网格）<br/>
                    工程设备:焊接器9个，切割机9个 （每网格）<br/>
                    钻头上限9个 （每网格）<br/>
                    氢电上限8个 （每网格）<br/>
                    氢氧制造机（包括冷却剂制造机与化油器）上限8个 （每网格）<br/>
                    太阳能板上限12个 （每网格）<br/>
                    风电上限12个 （每网格）<br/>
                    室内炮塔16个 （每网格）<br/>
                    重力发生器10个（每玩家） <br/>
                    炼油厂8个（每玩家且每网格） <br/>
                    <strong>
                        超过强制部分的设备将会被自动关闭/删除 <br/>
                        除以上限定外，所有性能占用超过500us的网格有严重性能问题，服主保留删除权利 <br/>
                        200us以上为较大性能问题，应考虑自行整改 <br/>
                        只允许小型重力炮船，且当造成较大性能问题时服主保留移除网格的权力 <br/>
                    </strong>
                </div>
                <h1>游玩宇宙不指定具体某种方块的数量，允许玩家在不卡服务器的情况下自由建造</h1>
            </div>
        );
    }
}

export default GridsRule;