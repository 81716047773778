import React, {Component} from 'react';
import "../WorkOrder/index.css"
class WorkOrder extends Component {
    render() {
        return (
            <div className={'ifr'}>
                <iframe src={"https://www.wjx.cn/vm/QsD1VnS.aspx"}/>
            </div>
        );
    }
}

export default WorkOrder;