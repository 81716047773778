import React, {Component} from 'react';
import {get, postUrlEncoding} from "../../../../utils/requests";
import {getWorldsGridsByServerName, saveGridToGarage} from "../../../../utils/urls";
import {formatCurrency, getColumns} from "../../../../utils/utils";
import {Col, Layout, Row, Table} from "antd";
import {Content, Header} from "antd/es/layout/layout";
import ServerSelector from "../../../ServerSelector";

class GridAsset extends Component {
    state = {
        selectedServerName: null,
        data: []
    }
    fetch = () => {
        get(getWorldsGridsByServerName + "?serverName=" + this.state.selectedServerName).then(resp => resp.json())
            .then(resp => {
                if (resp.code !== 200) {
                    alert(resp.msg)
                } else {
                    this.setState({
                        data: resp.data
                    })
                }
            })
    }


    componentDidMount() {
        let serverName = this.props.serverInfos[0]?.serverName;
        if (serverName !== null) {
            this.state.selectedServerName = serverName
            this.fetch()
        }
    }

    serverChange = (value)=>{
        this.state.selectedServerName = value
        this.fetch()
    }
    saveToGarage = (displayName) => {
        postUrlEncoding(saveGridToGarage, [
            ['serverName', this.state.selectedServerName],
            ['gridDisplayName', displayName]
        ]).then(r => r.json()).then(resp => {
            alert(resp.msg)

            setTimeout(this.fetch, 500)
        })

    }
    optionRender = (text, record) => {
        return <a href={'_'} onClick={(event) => {
            event.preventDefault()
            this.saveToGarage(record.displayName)
        }}>保存到船坞</a>
    }


    render() {

        const columns = getColumns([
            ['名称', 'displayName'],
            ['估价', 'price'],
            ['方块数', 'blocksCount'],
            ['pcu', 'pcu'],
            ['操作', 'option', this.optionRender]
        ])
        const data = this.state.data?.map((item, index) => {
            item.key = item.displayName + '' + index
            item.price = formatCurrency(item.price.toFixed(0))
            return item
        })
        return (
            <div>
                <Layout>
                    <Header style={{backgroundColor: 'white'}}>
                        网格列表
                    </Header>
                    <Content>
                        <Row>
                            <Col>
                                <ServerSelector serverInfos={this.props.serverInfos}
                                                onChange={value => this.serverChange(value)}
                                />
                            </Col>
                            <Col offset={1}>
                                <button onClick={this.fetch}>刷新网格列表</button>
                            </Col>
                        </Row>
                        <Table
                            columns={columns} dataSource={data}/>
                    </Content>
                </Layout>
            </div>
        );
    }
}

export default GridAsset;