import React, {Component} from 'react';
import {Button, Col, Modal, Row} from "antd";
import {TopMenu} from "../Menu/TopMenu";
import {LeftMenu} from "../Menu/LeftMenu";
import "./index.css"
import ServerIntroduction from "../Introductions/ServerIntroduction";
import IntroductoryCourse from "../Introductions/IntroductoryCourse";
import OtherCommands from "../Introductions/OtherCommands";
import SelfCommands from "../Introductions/SelfCommands";
import WorkOrder from "../Introductions/WorkOrder";
import {MenuFoldOutlined, MenuUnfoldOutlined} from "@ant-design/icons";
import Introductions from "../Introductions";
import PersonalCenter from "../PersonalCenter";
import {Switch} from "react-router-dom";
import {Redirect, Route} from "react-router";
import ServerControl from "../ServerControl";

class Index extends Component {







    //...的话要传入一个对象 而不是函数 所以要包一下
    functions = {
        changeIntroduction: this.changeIntroduction,
    }

    render() {

        return (

            <div className={'index'} style={{}} >
                <Row >
                    <Col xs={{ span:24}}  sm={{offset:12, span:12}} >
                        <TopMenu />
                    </Col>
                </Row>
                <Row>
                    <Col span={24} >
                        <Switch>
                            <Route path={'/Introductions'}><Introductions /></Route>
                            <Route path={'/WorkOrder'}><WorkOrder/></Route>
                            <Route path={'/ServerControl'}><ServerControl/></Route>
                            <Route path={'*'}><Redirect to={'/Introductions'}/></Route>
                        </Switch>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Index;