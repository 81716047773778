import React, {Component} from 'react';

class OtherCommands extends Component {
    render() {
        return (
            <div className={'textContent'}>
                <h2>护盾</h2>
                /shield ShowOnHud add 添加护盾显示到屏幕上 <br/><br/>
                <h2>限额相关</h2>
                !blocklimit mylimit 显示玩家的限额信息<br/>
                !blocklimit limits 显示当前限额信息<br/>
                !blocklimit update mylimit 强制刷新服务器上玩家的限制。有5分钟的冷却时间<br/><br/>
                {/*!blocklimit pairnames 列出服务器上所有可能的配对名称<br/>*/}
                {/*!blocklimit definitions 列出服务器上所有可能的块定义<br/>*/}
                <h2>修复飞船bug</h2>
                !fixship (用于修复船只的bug 对准船只输入后可以修复大部分bug)<br/>
            </div>
        );
    }
}

export default OtherCommands;