import React, {Component} from 'react';
import './index.css'
class BeiAn extends Component {
    render() {
        return (
        <div className={'beiAn'} style={{margin:'0 auto', padding:'20px 0',lineHeight:'1rem',fontSize:'1rem'}}  >

            <a target="_blank"  href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=35010402351342"
               style={{zIndex:'10',display:'inline-block',textDecoration:'none',height:'20px',lineHeight:'20px'}} rel="noreferrer"
            >
                <img src="备案图标.png" style={{float:'left',height:'2rem',width:'2rem'}} />
                <p style={{float:'left',height:'20px',lineHeight:'20px',margin:' 0px 0px 0px 5px',color:'#939393'}}>闽公网安备 35010402351342号</p>
            </a>
            <br/>
            <a target="_blank"  href="http://beian.miit.gov.cn/"
               style={{zIndex:'10',display:'inline-block',textDecoration:'none',height:'20px',lineHeight:'20px'}} rel="noreferrer"
            >

                <p style={{float:'left',height:'20px',lineHeight:'20px',margin:' 0px 0px 0px 5px',color:'#939393'}}>闽ICP备2022001946号-1</p>
            </a>


        </div>
        );
    }
}

export default BeiAn;