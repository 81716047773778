import React, {Component} from 'react';
import '../ruleContent.css'
class OrderRule extends Component {
    render() {
        return (
            <div>
                <h1>捞船须知：</h1>
                <div className={'textContent'}>
                    服务器即将重启的时候，请确保处于重力圈内的网格已转站，避免重启后偶然的推进器与护盾短暂失效导致船坞坠毁<br/>
                    <br/>
                    <h2>具体规则：</h2>
                    <strong>1、只有因服务器网络波动，卡顿等异常导致的损失可以申请捞船/补偿</strong><br/>
                    2、被不遵守pvp规则进行攻击时，可申请补偿<br/>
                    3、不接受新手船相关申请<br/>
                    4、使用脚本的船只不享受任何捞船服务。禁止使用驱磁。使用重力引擎的网格不享受任何捞船服务。<br/>
                    5、不接受因使用“闪点战略折越引擎”而撞毁/损失的网格申请（每人一次例外机会）<br/>
                    6、每名玩家一个月仅有两次捞船机会。<br/>
                    7、申请后 补偿和打捞船体将在12h-48h内发放与恢复<br/>
                    8、需要在发现/发生损失后立即截图私聊群主。<br/><br/>
                    <h2>补充规则：</h2>
                    <strong> 1、参与了任何pvp船只不享受捞船。即使pvp中产生了bug/服务器问题</strong><br/>
                    2、未改名的网格不享受捞船，
                    要求格式为:
                    阵营名_玩家名_飞船名 ，或者至少为:玩家名_飞船名 。其中玩家名/阵营名可以使用简写等，飞船名字自己取。<br/>
                    <strong> 3、被更改过、取出过东西的网格不享受捞船</strong>(你保修的时候把电脑cpu拆了 看看京东给你换货吗)<br/>
                    <strong style={{color:'indianred'}}>
                        3、请使用<em style={{color:'red'}}>!网格 工单</em> 指令把需要进行工单的网格进行保存 详见指令列表<br/>
                    </strong>

                </div>

            </div>
        );
    }
}

export default OrderRule;