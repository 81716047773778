import { Menu } from 'antd';

import React, {Component} from "react";
import 'antd/dist/antd.css';
import WorkOrder from "../../Introductions/WorkOrder";

import Introductions from "../../Introductions";

import ServerControl from "../../ServerControl";
import PersonalCenter from "../../PersonalCenter";
import {Link} from "react-router-dom";

const { SubMenu } = Menu;

export class TopMenu extends Component {
    state = {
        theme: 'dark',
        current: null,
    };

    handleClick = e => {
        let doc = null
        switch (e.key){
            case 'WorkOrder': doc = <WorkOrder/>;break;
            case 'Introductions': doc = <Introductions/>;break;
            case 'ServerControl': doc = <ServerControl/>;break;
            default: return;
        }
        this.props.changeBody(doc)
    };

    render() {
        const { current } = this.state;
        function getItem(label, key, icon, children, type) {
            return {
                key,
                icon,
                children,
                label,
                type,
            };
        }
        const menus = [
            getItem(<Link to={'/ServerControl'}>服务器控制台</Link>,"ServerControl"),
            getItem(<Link to={'/Introductions'}>主页</Link>,"Introductions"),
            getItem(<Link to={'/WorkOrder'}>前往工单</Link>,"WorkOrder"),
            getItem(<PersonalCenter/>,"PersonalCenter"),
            getItem(<a href="https://" target="_blank" rel="noopener noreferrer">
                反馈bug(未完工)
            </a>,"fankuibug"),


        ]
        return (
            <Menu
                  selectedKeys={[current]}
                  mode="horizontal"
                  theme={this.state.theme}
                  style = {{height:'3rem',padding:0,width:'100%'}}
                  items = {menus}
            />
        );
    }
}