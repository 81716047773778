import React, {Component} from 'react';
import {Col, Row, Statistic} from "antd";
import Text from "antd/es/typography/Text";
import GridMeasureProfile from "../../GridMeasureProfile";

class ServerInfo extends Component {
    state: {
        serverName: '',
        onlinePlayers: [],
        simulationRatio: 1.0
    }

    render() {
        const {serverName, onlinePlayers, simulationRatio} = this.props

        return (
            <div style={{marginTop:'2rem',backgroundColor:'white'}}>
                <Row>
                    <Col >
                        <strong>{serverName}</strong>
                    </Col>
                </Row>
                <Row>
                    <Col >
                        <Statistic title="在线人数" value={onlinePlayers.length}/>
                    </Col>
                    <Col offset={2}>
                        <Statistic title="模拟速率" value={simulationRatio} precision={1}/>
                    </Col>
                    <Col style={{textAlign:'left'}} offset={2}>
                        <strong> 玩家列表:</strong>
                       <br/>
                        {
                            onlinePlayers.map(((name, index) => {
                                return (
                                    <Text  key={index}>{name}<br/></Text>
                                )
                            }))
                        }
                    </Col>
                </Row>

            </div>


        );
    }
}

export default ServerInfo;