import React, {Component} from 'react';
import {getAllServerInfos, getBillByServerName} from "../../../utils/urls";
import {AutoComplete, Col, Row, Select, Table} from "antd";
import {Option} from "antd/es/mentions";
import * as urls from "../../../utils/urls";
import {getColumns, unique} from "../../../utils/utils";

class BillMarket extends Component {

    state = {
        selectedServerName:'',
        bills:[],
        filterItemName:null

    }

    componentDidMount() {
        const {serverInfos} = this.props
        if(serverInfos.length>0){
            this.handleChange(serverInfos[0].serverName)
        }
    }

    flush = ()=>{
        let params = new URLSearchParams()

        params.append("serverName",this.state.selectedServerName)

        fetch(urls.getBillByServerName+"?"+params.toString(),{
            method:"get",

        }).then(res=>res.json()).then(response=>{

            if(response.code===200){

                this.setState({bills:response.data})
            }else{
                alert(response.msg)
            }
        })
    }
    handleChange = (value)=>{
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.selectedServerName = value;
        this.flush();
    }

    filterChange = (value)=>{

        this.setState({filterItemName:value})
    }


    render() {
        const {serverInfos} = this.props
        const {bills,filterItemName} = this.state
        if(serverInfos.length<=0){
            return(<div>搜索服务器失败!</div>)
        }
        const options = unique(bills.map(item=>{
            return item.itemName
        })).map((itemName=>{
            return {value:itemName}
        }))
        const columns = getColumns([
            ["订单编号","id"],
            ["订单发布者","ownerDisplayName"],
            ["订单类型","orderType"],
            ["物品名称","itemName"],
            ["订单单价","univalence"],
            ["订单数量","count"],
            ])
        const data =   bills.filter(bill=>{
            if(filterItemName===null||filterItemName===''){
                return true;
            }
            return bill.itemName===filterItemName
        }).map((bill)=>{
            return {
                key:bill.id,
                id:bill.id,
                ownerDisplayName:bill.ownerDisplayName,
                orderType:bill.orderType==='Order'?'卖单':'收单',
                itemName:bill.itemName,
                univalence:bill.univalence,
                count:bill.count
            }
        })

        return (
            <div>
                <Row style={{marginTop:'3rem'}}>
                    <Col style={{}}  span={6}>
                        过滤物品:&nbsp;&nbsp;
                        <AutoComplete
                            style={{ width: 100 }}
                            options={options}
                            placeholder="输入物品名"
                            allowClear={true}
                            onChange={this.filterChange}
                            filterOption={true}
                        >
                        </AutoComplete>
                    </Col>
                    <Col >
                        选择服务器:&nbsp;&nbsp;
                        <Select defaultValue={serverInfos[0].serverName} style={{ width: 120 }} onChange={this.handleChange}>
                            {
                                serverInfos.map((serverInfo,index)=>{
                                   return <Select.Option key={index} value={serverInfo.serverName}>{serverInfo.serverName}</Select.Option>
                                })
                            }
                        </Select>
                    </Col>
                </Row>
                <Row>
                    <Col  span={24}>
                        <Table columns={columns} dataSource={data} rowKey={(recode)=>recode.id}/>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default BillMarket