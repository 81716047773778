import React, {Component} from 'react';
import {marked} from "marked";
import PropTypes from "prop-types";

//传入mdUrl即可
class MdContent extends Component {

    state = {
        mdStr:''
    }
    static propTypes = {
        mdUrl: PropTypes.string.isRequired,
    }

    async componentDidMount() {
        const url = this.props.mdUrl;
        let resp =   await fetch(url, {
            method: "GET"
        })

        const blob = await resp.blob()
        this.setState({
            mdStr:await blob.text()
        })

    }

    //https://github.com/i5ting/tocmd.npm
    //前往目标路径 然后 i5ting_toc -f sample.md -o
    render() {
        const parse = marked.parse(this.state.mdStr);
        // console.log(parse)
        return (
            // <div style={{height:'100%'} }>

            <div className={'textContent'} dangerouslySetInnerHTML={{__html:parse}} >

                {/*<iframe  style={{height:'100%'} } src={"../srcs/md.html"}/>*/}
                {/*<iframe  style={{height:'100%'} } src={"../srcs/preview/commands.html"}/>*/}

            </div>
        );
    }
}

export default MdContent;