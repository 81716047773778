export function postWithAuthJson(url,body){
  return  fetch(url,{
        method:"post",
        body:JSON.stringify(body),
        headers:{
            "Content-Type":"application/json"
        }
    })
}
export function postJson(url,body){
    return  fetch(url,{
        method:"post",
        body:JSON.stringify(body),
        headers:{
            "Content-Type":"application/json"
        }
    })
}
export function get(url,param){
    return  fetch(url,{
        method:"get"
    })
}
export function getUrlEncoding(url,param){
    let u = new URLSearchParams()
    for(let key in param){
        u.append(key,param[key])
    }
    return  fetch(url+'?'+u.toString(),{
        method:"get",

    })
}
export function getWithAuth(url){
    return  fetch(url,{
        method:"get",
    })
}
export function postUrlEncoding(url,params){

    const param = new URLSearchParams()
    params.forEach(pair=>{
        param.append(pair[0],pair[1])
    })
    return  fetch(url,{
        method:"post",
        body:param
    })
}
