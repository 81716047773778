import React, {Component} from 'react';
import '../ruleContent.css'
class BugRule extends Component {
    render() {
        return (
            <div>
                <h1>bug奖惩规定：</h1>
                <div className={'textContent'}>
                    1、故意利用任何bug和非常规手段者，将受到封禁，传播者同上<br/>
                    2、对使用者进行包庇、故意之情不报者 将受到封禁<br/>
                    3、汇报bug和非常规获利途径、数值极端不平衡导致的平衡性问题者，可以获得特殊奖励。举报bug传播者奖励同上。<br/>
                    4、保留对非法获利者/阵营采取连坐惩罚的可能。<br/>
                    <br/><h2>  反馈问题的奖励如下:</h2>
                    <br/>
                    <strong>奖励机制是灵活的，服主不一定会给每次反馈都发放奖励，或者合并多次反馈为一次大的奖励发放。可以主动向服主提出申请奖励</strong><br/>
                    1、反馈普通错误（如翻译错误/指令出错（exception））等不直接影响游戏正常运行的:奖励20先锋设计图。<br/>
                    2、反馈严重漏洞（可以非常规手段获取大量资源等，包括程序漏洞/数值设计问题等）:视严重性一般奖励50-100先锋设计图/精英工程师时间奖励，上不封顶。<br/>
                    3、反馈建议被采纳的:视价值给予奖励。<br/>
                    4、服主从收到问题反馈到修复需要一定时间，并且为安全考虑不一定会发公告，期间第一位反馈的玩家才会收到全额奖励，后面反馈的玩家不发放或少发放奖励。<br/>
                    <br/><h2> 服务器已知但无法修复的bug:</h2>

                    1、焊接器失效:焊接器在焊接投影仪新方块时，有时候会失效，可以尝试手动放入材料，或者手动焊接底层材料。<br/>
                    2、半高错位:额外装甲中的半高装甲块在使用投影仪焊接的时候会有错位问题，解决方案为手动放置框架再焊接。<br/>
                    3、0方块网格:信息中可能会出现方块数/pcu数为0的网格，这些网格可能拥有错误的实体id，请勿删除此类网格，否则你的其他网格将会被误删。一般服务器重启后会修正<br/>
                    4、仓库指令显示找不到船只/箱子。可能是因为有子网格导致的。请断开所有连接器/起落架后再重试。<br/>
                    5、自动建造指令使用失败：可能某些方块被焊接器焊接过，导致无法进行自动建造。可以考虑把那些方块拆掉重新摆放。<br/>
                </div>


            </div>
        );
    }
}

export default BugRule;