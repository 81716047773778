import React, {Component} from 'react';
import Text from "antd/es/typography/Text";
import '../ruleContent.css'
class PVPRule extends Component {
    render() {
        return (
            <div>
                <h1>服务器pvp范围公示：</h1>
                <div className={'textContent'}>
                    <h3 style={{color:'red'}}>除了PVP保护区和安全区外，其他所有空间都可以进行pvp活动，但是需要遵顼以下规则</h3>
                    1、禁止利用安全区进行任何形式的pvp行为<br/>
                    2、禁止对安全区和安全区内网格进行任何形式pvp行为。包括对别人的安全区进行射击。<br/>
                    3、禁止在服营交易站5km内pvp行为，在靠近交易站时请关闭武器，若您的武器开火，则视为违反条例。<br/>
                    4、禁止内鬼（间谍）行为<br/>
                    &nbsp;&nbsp;&nbsp;4.1 若攻击方并非有意违反规则，则解释清楚后，可与受害者达成和解私下解决，或服主裁定需要全额赔偿受害者所有损失。<br/>
                    &nbsp;&nbsp;&nbsp;4.2 若攻击方拒绝解释/装死（群里私聊不回复），则服主会恢复受害者的财产，并且对攻击方实行游戏内封禁，直至其承诺作出双倍赔偿。<br/>
                    5、请善用船坞系统保护您珍贵的船只,您可以在上线后再将需要使用的船只提取使用。<br/>
                    <h3 style={{color:'red'}}>PVP保护区规则：</h3>
                      游玩宇宙系列服务器中，设定地球为pvp保护区 玩家不能在地球进行pvp活动 同时服务器会拦截地球重力区域内的恶意攻击伤害，如果同阵营想要协作，需要输入<br/>
                    <strong style={{color:'red'}}>!设置 保护区同阵营伤害</strong> 来允许队友焊接/拆卸你的网格<br/>
                    银河帝国系列服务器没有设置特别的保护区。
                    <h2>违反服务器PVP规定需要全额支付受害者材料赔偿 并封禁至少24小时，上不封顶。</h2>
                </div>

            </div>
        );
    }
}

export default PVPRule;