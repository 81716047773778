import React, {Component} from 'react';
import {getLatestMeasureResult} from "../../../utils/urls";
import {get} from "../../../utils/requests";
import {getColumns} from "../../../utils/utils";
import {Layout, Table} from "antd";
import {Content, Header} from "antd/es/layout/layout";
import ServerSelector from "../../ServerSelector";

class GridMeasureProfile extends Component {
    state = {
        selectedServerName: null,
        data: []
    }
    fetch = () => {
        get(getLatestMeasureResult + "?serverName=" + this.state.selectedServerName).then(resp => resp.json())
            .then(resp => {
                if (resp.code !== 200) {
                    alert(resp.msg)
                } else {
                    this.setState({
                        data: resp.data
                    })
                }
            })
    }

    changeServer = (value)=>{
        this.state.selectedServerName = value
        this.fetch()
    }
    componentDidMount() {
        let serverName = this.props.serverInfos[0]?.serverName;
        if (serverName !== null) {
            this.state.selectedServerName = serverName
            this.fetch()
        }
    }

    render() {
        const columns = getColumns([
            ['所有者', 'playerDisplayName'],
            ['所有者阵营', 'factionName'],
            ['网格名', 'entityDisplayName'],
            ['耗时(ms)', 'mainThreadTimePerTickMills']
        ],'ascend')
        const totalMills = this.state.data.reduce((total,current)=>{
            return total+current.mainThreadTimePerTickMills;
        },0.0)
        const data = this.state.data?.map(item => {

            item.key = item.entityDisplayName+item.playerDisplayName+item.mainThreadTimePerTickMills

            item.mainThreadTimePerTickMills = Number(item.mainThreadTimePerTickMills.toFixed(2))
            return item
        })

        return (
            <div>
                <Layout>
                    <Header style={{backgroundColor: 'white'}}>
                        网格耗时
                    </Header>
                    <Content>
                        <ServerSelector serverInfos={this.props.serverInfos}
                                        onChange={value => this.changeServer(value)}
                        />
                        <div>总耗时:{totalMills.toFixed(2)}毫秒</div>
                        <Table
                            columns={columns} dataSource={data}/>
                    </Content>
                </Layout>
            </div>
        );
    }
}

export default GridMeasureProfile;