
// const serverHost = "http://localhost:3000/api/"
// const serverHostStatic = "http://localhost:3000/"
// const socketHost = "ws://localhost:8081/"

const serverHost = "https://galacticserver.net/yinhe/"
const serverHostStatic = "https://galacticserver.net/"
const socketHost = "wss://galacticserver.net/websocket/"



export const login = serverHost+"login"
export const register = serverHost+"user/register"
export const logout = serverHost+"logout"
export const updateGamePassword = serverHost+"user/updateGamePassword"
export const getAllServerInfos = serverHost+"server/simpleInfos"
export const getStorageByServerName = serverHost+"storage/getByServerName"
export const getWorldsGridsByServerName = serverHost+"grid/getWorldsGridsByServerName"
export const saveGridToGarage = serverHost+"grid/saveToGarage"
export const getGridOwnerShipLogs = serverHost+"grid/getOwnerShipLogs"
export const getOwnerShipLogsDays = serverHost+"grid/getOwnerShipLogsDays"
export const getLatestMeasureResult = serverHost+"grid/getLatestMeasureResult"
export const getBillByServerName = serverHost+"bill/getByServerName"
export const sendCommand = serverHost+"command/send"
export const currentUser = serverHost+"user/currentUser"

export const getMdZip = serverHostStatic+"srcs/galaxy-empire-wiki.zip"
export const getSelfCommandMd = serverHostStatic+"srcs/commands.md"
export const getIntroductoryCourse = serverHostStatic+"srcs/IntroductoryCourse.md"
export const systemMonitorSocket = socketHost+"systemMonitor"