import React, {Component} from 'react';
import {Button, Col, Layout, Row} from "antd";

import {MenuFoldOutlined, MenuUnfoldOutlined} from "@ant-design/icons";

import ServerIntroduction from "./ServerIntroduction";
import Sider from "antd/es/layout/Sider";
import {Content} from "antd/es/layout/layout";
import {Route, Switch} from "react-router-dom";
import IntroductoryCourse from "./IntroductoryCourse";
import OrderRule from "./ServerRules/OrderRule";
import WorkOrder from "./WorkOrder";
import GridsRule from "./ServerRules/GridRule";
import PVPRule from "./ServerRules/PVPRule";
import OtherCommands from "./OtherCommands";
import SelfCommands from "./SelfCommands";
import ProgramBlockRule from "./ServerRules/ProgramBlockRule";
import BugRule from "./ServerRules/BugRule";
import OtherRule from "./ServerRules/OtherRule";
import PiratesIntroduction from "./PiratesIntroduction";
import {Redirect, withRouter} from "react-router";
import LeftMenu from "../Menu/LeftMenu";

class Introductions extends Component {
    state = {
        openMenuNoticeDisplay:'block'
    }


    toggleCollapsed  =()=>{
        if(!this.state.leftMenuCollapsed){
            this.setState({leftSpan:0,leftMenuCollapsed:true})
        }else{
            this.setState({leftSpan:4,leftMenuCollapsed:false})
        }
    }

/*    componentDidMount() {
        //手机端优先隐藏侧边栏 pc端优先展示侧边栏
        if(document.body.clientWidth<=768){
            this.setState({leftSpan:0,leftMenuCollapsed:true,openMenuNoticeDisplay:'block'})
        }else{
            this.setState({leftSpan:4,leftMenuCollapsed:false,openMenuNoticeDisplay:'none'})
        }
    }*/



    render() {


        const {leftMenuCollapsed} = this.state
        return (
            <div className={'index'} style={{}} >
                <Layout>
                    <Sider
                        // collapsible
                        breakpoint="lg"
                        collapsedWidth="0"
                    >
                        <LeftMenu collapsed = {leftMenuCollapsed} />
                    </Sider>
                    <Content className={'Introduction'}>
                        <Switch>
                            <Route path={`${this.props.match.path}/ServerIntroduction`}><ServerIntroduction/></Route>
                            <Route path={`${this.props.match.path}/IntroductoryCourse`}><IntroductoryCourse/></Route>
                            <Route path={`${this.props.match.path}/SelfCommands`}><SelfCommands/></Route>
                            <Route path={`${this.props.match.path}/OtherCommands`}><OtherCommands/></Route>
                            <Route path={`${this.props.match.path}/PVPRule`}><PVPRule/></Route>
                            <Route path={`${this.props.match.path}/GridsRule`}><GridsRule/></Route>
                            <Route path={`${this.props.match.path}/WorkOrder`}><WorkOrder/></Route>
                            <Route path={`${this.props.match.path}/ProgramBlockRule`}><ProgramBlockRule/></Route>
                            <Route path={`${this.props.match.path}/OrderRule`}><OrderRule/></Route>
                            <Route path={`${this.props.match.path}/BugRule`}><BugRule/></Route>
                            <Route path={`${this.props.match.path}/OtherRule`}><OtherRule/></Route>
                            <Route path={`${this.props.match.path}/PiratesIntroduction`}><PiratesIntroduction/></Route>
                            <Route path={`*`}><Redirect to={`${this.props.match.path}/ServerIntroduction`}/></Route>
                        </Switch>
                        {/*{this.state.introductionPage}*/}
                    </Content>

                </Layout>

            </div>
        );
    }
}

export default withRouter(Introductions);