import React, {Component} from 'react';
import {Button, Input, Modal} from "antd";
import {Consumer} from "../../App";
import Text from "antd/es/typography/Text";
import {currentUser, login, logout, register, updateGamePassword} from "../../utils/urls";
import {get, postJson, postUrlEncoding} from "../../utils/requests";

class PersonalCenter extends Component {
    state = {
        isPersonalCenterVisible: false,
        isLoginVisible: false,
        isRegisterVisible: false,
        loginUsername: '',
        loginPassword: '',
        registerUsername: '',
        registerPassword: '',
        registerSteamId: '',
        gamePassword: ''

    }
    toPersonalCenter = () => {
        this.setState({
            isPersonalCenterVisible: true,
            isRegisterVisible: false,
            isLoginVisible: false
        })
    }
    cancelAll = () => {
        this.setState({
            isPersonalCenterVisible: false,
            isRegisterVisible: false,
            isLoginVisible: false
        })
    }
    toLogin = () => {
        this.setState({
            isPersonalCenterVisible: false,
            isRegisterVisible: false,
            isLoginVisible: true
        })
    }
    toRegister = () => {
        this.setState({
            isPersonalCenterVisible: false,
            isLoginVisible: false,
            isRegisterVisible: true
        })
    }
    register = () => {
        const {registerUsername, registerPassword, registerSteamId} = this.state
        if (registerUsername === '') {
            alert("用户名为空!")

        } else if (registerPassword === '') {
            alert("密码为空!")

        } else if (registerSteamId === '') {
            alert("steamId为空!")

        } else {
            postJson(register, {
                username: registerUsername,
                password: registerPassword,
                steamId: registerSteamId
            }).then(resp => resp.json()).then(resp => {
                if (resp.code === 200) {
                    alert('注册成功!')
                    this.toLogin()
                } else {
                    alert(resp.msg)
                }
            })
        }
    }
    updateGamePassword = () => {
        const {gamePassword} = this.state
        if (gamePassword === '') {
            alert('密码不能为空!')
        } else {
            postUrlEncoding(updateGamePassword, [['gamePassword', gamePassword]])
                .then(resp => resp.json())
                .then(resp => {
                    alert(resp.msg)
                })
        }
    }
    login = (userData) => {
        const {loginUsername, loginPassword} = this.state
        if (loginUsername === '') {
            alert("用户名为空!")

        } else if (loginPassword === '') {
            alert("密码为空!")
        } else {
            let param = new URLSearchParams();
            param.append('username', loginUsername)
            param.append('password', loginPassword)
            postUrlEncoding(login, [['username', loginUsername], ['password', loginPassword]])
                .then(resp => resp.json()).then(resp => {
                if (resp.code === 200) {
                    alert('登录成功!')
                    get(currentUser)
                        .then(resp => resp.json())
                        .then(resp => {
                            if (resp.code === 200) {
                                userData.steamId = resp.data.steamId
                                userData.username = resp.data.username
                                userData.modify(userData)
                            }
                            this.toPersonalCenter()
                        })

                } else {
                    alert(resp.msg)
                }
            })
        }
    }

    logOut = (userData) => {
        this.setState({
            isPersonalCenterVisible: true,
            isRegisterVisible: false,
            isLoginVisible: false
        })
        userData.username = null
        userData.password = null
        userData.modify(userData)

        postUrlEncoding(logout, [])
            .then(resp => resp.json())
            .then(resp => {
                if (resp.code === 200) {
                    alert('已注销!')
                } else {
                    alert(resp.msg)
                }
                this.toPersonalCenter()
            })

    }


    render() {
        const {isLoginVisible, isRegisterVisible, isPersonalCenterVisible} = this.state
        return (
            <Consumer>
                {
                    (userData) => {
                        const logged = userData.username !== null
                        return <div>
                            <div onClick={this.toPersonalCenter}>个人中心</div>
                            <Modal title="个人中心"
                                   visible={isPersonalCenterVisible}
                                   onCancel={() => this.setState({isPersonalCenterVisible: false})}
                                   footer={[
                                       <button key={'login'} onClick={this.toLogin}>登录</button>,
                                       <button key={'register'} onClick={this.toRegister}>注册</button>,
                                       <button key={'logout'} style={{display: logged ? 'inline-block' : 'none'}}
                                               onClick={() => this.logOut(userData)}>注销
                                       </button>
                                   ]}
                            >
                                <div style={{display: !logged ? 'inline-block' : 'none'}}>
                                    <Text>{'您未登录'}</Text><br/>
                                </div>

                                <div style={{display: logged ? 'inline-block' : 'none'}}>
                                    <Text>{'欢迎 ' + userData.username}</Text><br/>
                                    <Text>{'steamId: ' + userData.steamId}</Text><br/>
                                    <Input.Password
                                        onChange={event => this.setState({gamePassword: event.target.value})}
                                        placeholder="绑定游戏密码"/>
                                    <button onClick={this.updateGamePassword}>绑定密码</button>
                                    <div>{'用于与游戏账号进行绑定 需要在游戏内通过'}</div>
                                    <div>{'!设置 密码 [具体密码] 进行设置 然后在此处输入你设置的密码进行账号绑定'}</div>
                                </div>

                            </Modal>
                            <Modal title="登录"
                                   visible={isLoginVisible}

                                   onCancel={() => this.setState({isLoginVisible: false})}
                                   footer={[
                                       <Button key={'login'} onClick={() => this.login(userData)}>登录</Button>
                                   ]}

                            >
                                <Input placeholder={'请输入用户名'}
                                       onChange={(event) => this.setState({loginUsername: event.target.value})}/>
                                <Input.Password placeholder={'请输入密码'}
                                                onChange={(event) => this.setState({loginPassword: event.target.value})}/>
                            </Modal>
                            <Modal title="注册"
                                   visible={isRegisterVisible}

                                   onCancel={() => this.setState({isRegisterVisible: false})}
                                   footer={[
                                       <Button key={'submit'} onClick={this.register}>提交注册</Button>
                                   ]}
                            >

                                <Text>steamId请在游戏内输入!info myinfo获取。</Text><br/>
                                <Text>不提供网站密码修改功能，如忘记密码请重新注册账号</Text>
                                <Input placeholder={'请输入要绑定的steamId'}
                                       onChange={(event) => this.setState({registerSteamId: event.target.value})}/>
                                <Input placeholder={'请输入网站用户名(用于登录服务器网站)'}
                                       onChange={(event) => this.setState({registerUsername: event.target.value})}/>
                                <Input placeholder={'请输入网站密码(用于登录服务器网站)'}
                                       onChange={(event) => this.setState({registerPassword: event.target.value})}/>

                            </Modal>
                        </div>
                    }


                }

            </Consumer>
        );
    }
}

export default PersonalCenter;