import React, {Component} from 'react';
import {Layout, Table} from "antd";
import {Content, Header} from "antd/es/layout/layout";
import {getColumns} from "../../../../utils/utils";
import {getStorageByServerName} from "../../../../utils/urls";
import {get} from "../../../../utils/requests";
import ServerSelector from "../../../ServerSelector";

class StorageAsset extends Component {
    state={
        selectedServerName: null,
        data:[]
    }
    fetch = ()=>{
        get(getStorageByServerName+"?serverName="+this.state.selectedServerName).then(resp=>resp.json())
            .then(resp=>{
                if(resp.code!==200){
                    alert(resp.msg)
                }else{
                    this.setState({
                        data:resp.data
                    })
                }
            })
    }
    serverChange = (value)=>{
        this.state.selectedServerName = value
        this.fetch()
    }
    componentDidMount() {
        let serverName = this.props.serverInfos[0]?.serverName;
        if(serverName!==null){
            this.state.selectedServerName = serverName
            this.fetch()
        }
    }

    render() {
        const columns = getColumns([
            ['物品名称','itemName'],
            ['数量','count']
        ])
        const data = this.state.data
        return (
            <div>
                <Layout>
                    <Header style={{backgroundColor:'white'}}>
                        仓库资产列表
                    </Header>
                    <Content>
                        <ServerSelector serverInfos={this.props.serverInfos}
                                        onChange={value => this.serverChange(value)}
                        />
                        <Table
                            columns={columns} dataSource={data} rowKey={(recode)=>recode.itemName}/>
                    </Content>
                </Layout>
            </div>
        );
    }
}

export default StorageAsset;