
let initState = {
    time:null
}
export const reducer = (state = initState,action)=>{
    if(action.type==='ADD'){
        return {
            time:action.value
        }
    }
    return state;
}