import React, {Component} from 'react';
import {Select} from "antd";

class ServerSelector extends Component {
    handleChange = (value)=>{
        this.props.onChange(value)
    }
    render() {
        const {serverInfos} = this.props
        return (
            <div>
                选择服务器:&nbsp;&nbsp;
                <Select defaultValue={serverInfos[0].serverName} style={{ width: 120 }} onChange={this.handleChange}>
                    {
                        serverInfos.map((serverInfo,index)=>{
                            return <Select.Option key={index} value={serverInfo.serverName}>{serverInfo.serverName}</Select.Option>
                        })
                    }
                </Select>
            </div>
        );
    }
}

export default ServerSelector;