import React, {Component} from 'react';

class PiratesIntroduction extends Component {
    render() {
        return (
            <div>
                <h1>海盗刷新规则：</h1>
                <div className={'textContent'}>
                    服务器有多重海盗机制，本章只介绍随机的常规刷新组机制，即主动攻击玩家的海盗机制。<br/>
                    海盗会每隔一段时间对满足条件的玩家发起攻击，随着玩家等级的提高，海盗攻击会越来越强。<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;1、玩家等级低于45级时处于新手保护阶段不会被攻击。<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;2、玩家处于重力圈范围内不会被攻击。<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;3、海盗只会对玩家刷出，如果网格周围没有玩家，不会遭到海盗主动攻击(已刷出的海盗不算)<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;4、海盗刷出前会对玩家进行提示，玩家可以收到警告后通过船坞保存飞船等手段规避海盗<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;5、如果玩家周围存在安全区，则海盗不会刷出<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;6、如果一名玩家被海盗攻击了，那么在接下来的一段时间内不会再被攻击(冷却保护)<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;7、每个周期，海盗会急切的寻找玩家发起攻击，这意味着如果玩家满足刷出条件 则下一轮几乎必定遭到海盗攻击。<br/>
                </div>


            </div>
        );
    }
}

export default PiratesIntroduction;