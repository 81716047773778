import React, {Component} from 'react';

import {AppstoreOutlined, MenuFoldOutlined, MenuUnfoldOutlined} from "@ant-design/icons";

import {Button, Col, Menu, Row} from "antd";
import ServerIntroduction from "../../Introductions/ServerIntroduction";
import IntroductoryCourse from "../../Introductions/IntroductoryCourse";
import SelfCommands from "../../Introductions/SelfCommands";
import OtherCommands from "../../Introductions/OtherCommands";
import WorkOrder from "../../Introductions/WorkOrder";
import BugRule from "../../Introductions/ServerRules/BugRule";
import OrderRule from "../../Introductions/ServerRules/OrderRule";
import GridsRule from "../../Introductions/ServerRules/GridRule";
import PVPRule from "../../Introductions/ServerRules/PVPRule";
import OtherRule from "../../Introductions/ServerRules/OtherRule";
import ProgramBlockRule from "../../Introductions/ServerRules/ProgramBlockRule";
import PiratesIntroduction from "../../Introductions/PiratesIntroduction";
import {getItem} from "../../../utils/utils";
import {Redirect, withRouter} from "react-router";

const {SubMenu} = Menu;

 class LeftMenu extends Component {

    state = {
        theme: 'dark',
        toggleCollapsedPosition:'relative',
        redirect:''
    }


    handleClick = e => {
        let key = e.key
        let doc

       this.setState({
           redirect:<Redirect to={`${this.props.match.url}/`+key}/>
       })



        //
        // switch (key) {
        //     case 'ServerIntroduction':
        //         doc = <ServerIntroduction/>;
        //         break;
        //     case 'IntroductoryCourse':
        //         doc = <IntroductoryCourse/>;
        //         break;
        //     case 'SelfCommands':
        //         doc = <SelfCommands/>;
        //         break;
        //     case 'OtherCommands':
        //         doc = <OtherCommands/>;
        //         break;
        //     case 'WorkOrder':
        //         doc = <WorkOrder/>;
        //         break;
        //     case 'PVPRule':
        //         doc = <PVPRule/>;
        //         break;
        //     case 'GridsRule':
        //         doc = <GridsRule/>;
        //         break;
        //     case 'ProgramBlockRule':
        //         doc = <ProgramBlockRule/>;
        //         break;
        //     case 'OrderRule':
        //         doc = <OrderRule/>;
        //         break;
        //     case 'BugRule':
        //         doc = <BugRule/>;
        //         break;
        //     case 'OtherRule':
        //         doc = <OtherRule/>;
        //         break;
        //     case 'PiratesIntroduction':
        //         doc = <PiratesIntroduction/>;
        //         break;
        // }

    };

    render() {
        const menus = [
            getItem('玩法相关','sub1',<AppstoreOutlined/>,
                [
                    getItem('服务器介绍',"ServerIntroduction"),
                    getItem('教程',"IntroductoryCourse"),
                    getItem('海盗规则(PVE)',"PiratesIntroduction"),
                ]
            ),
            getItem('指令列表','sub2',<AppstoreOutlined/>,
                [
                    getItem('自有指令',"SelfCommands"),
                    getItem('其他指令',"OtherCommands"),
                ]
            ),
            getItem('服务器规则(必看)','sub3',<AppstoreOutlined/>,
                [
                    getItem('PVP',"PVPRule"),
                    getItem('网格限额',"GridsRule"),
                    getItem('编程块',"ProgramBlockRule"),
                    getItem('工单/捞船',"OrderRule"),
                    getItem('Bug/问题反馈',"BugRule"),
                    getItem('其他',"OtherRule"),
                ]
            ),
        ]
        return (
            <div style={{textAlign: 'left'}}>
                <Menu
                    onClick={this.handleClick}
                    defaultSelectedKeys={['1']}
                    defaultOpenKeys={[]}
                    mode="inline"
                    theme={this.state.theme}
                    items = {menus}
                >
                </Menu>
                {
                    this.state.redirect
                }
            </div>
        );
    }
}

export default withRouter(LeftMenu)