import React, {Component} from 'react';
import * as urls from "../../../utils/urls";
import {getColumns} from "../../../utils/utils";
import { Col, Input, Row, Select, Table} from "antd";
import {getUrlEncoding} from "../../../utils/requests";

class OwnerShipLog extends Component {

    state = {
        selectedServerName: '',
        datesToSelect: [],//可选择的日期
        logs: [],
        selectedDate: null,
        filterKeyCode: "",
        isLoading: false,
        pagination: {
            current: 1,
            pageSize: 10,
            total: 0
        },

    }

    componentDidMount() {
        const {serverInfos} = this.props
        if (serverInfos.length > 0) {
            // this.handleChange(serverInfos[0].serverName)
        }
    }

    getDatesToSelect = () => {
        if (this.state.selectedServerName == null) {
            return
        }
        let params = new URLSearchParams()
        params.append("serverName", this.state.selectedServerName)
        fetch(urls.getOwnerShipLogsDays + "?" + params.toString(), {
            method: "get",
        }).then(res => res.json()).then(response => {
            if (response.code === 200) {

                this.state.datesToSelect = response.data

                if(response.data.length>0){
                    this.state.selectedDate = response.data[0]
                }


                 this.flush()
            }
             else {
                alert(response.msg)
            }
        })

    }
    flush = () => {



        this.setState({
            isLoading: true
        })
        let query = {
            date: this.state.selectedDate,
            keyword: this.state.filterKeyCode,
            pageSize: this.state.pagination.pageSize,
            page: this.state.pagination.current - 1,//实际应该从0开始
            serverName: this.state.selectedServerName
        }
        getUrlEncoding(urls.getGridOwnerShipLogs, query).then(r => r.json())
            .then(response => {
                if (response.code === 200) {
                    this.state.pagination.total = response.data.totalPages
                    this.setState({
                        logs: response.data.lines,
                    })

                } else {
                    alert(response.msg)
                }
                this.setState({
                    isLoading: false
                })
            })
    }
    handleServerNameChange = (value) => {
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.selectedServerName = value;

        //清空日期和分页选项
        this.state.selectedDate = null
        this.state.pagination = {
            current: 1,
            pageSize: 10,
            total: 0
        }
        this.state.logs = []

        this.state.selectedDate = null
        this.state.datesToSelect = []

        this.getDatesToSelect()


    }

    handleDateChange = (value) => {
        this.state.selectedDate = value
        //清空分页选项
        this.state.pagination = {
            current: 1,
            pageSize: 10,
            total: 0
        }
        this.flush()
    }

    filterChange = (event) => {

        this.state.filterKeyCode = event.target.value

    }

    tableChance = (pagination, filters, sorter) => {

        this.state.pagination = pagination

        this.flush()
        //  console.log(pagination)
    }


    render() {

        const {serverInfos} = this.props




        if (serverInfos.length <= 0) {
            return (<div>搜索服务器失败!</div>)
        }

        const columns = getColumns([
            ["日志行", "log"],
        ])


        let data = this.state.logs.map((line, index) => {
            return {
                log: line,
                Key: index
            }
        })


        let selectKeywordDisable = this.state.selectedServerName===''||this.state.selectedDate===null
        return (
            <div>
                <Row style={{backgroundColor: 'white', margin: '0',height:'5rem'}}>
                    <Col style={{textAlign:'center'}} span={24}>
                        网格破坏日志查询，先选择服务器，再选择日期。然后在搜索关键字中输入玩家名称，或者网格名字
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col key={1}>
                        搜索关键字:&nbsp;&nbsp;
                        <Input
                            style={{width: 150}}
                            placeholder="请输入搜索关键字"
                            allowClear={true}
                            onChange={this.filterChange}
                            onPressEnter={this.flush}
                            disabled={selectKeywordDisable}
                        >
                        </Input>
                    </Col>
                    <Col key={2}>
                        选择服务器:&nbsp;&nbsp;
                        <Select style={{width: 120}} onChange={this.handleServerNameChange}>
                            {
                                serverInfos.map((serverInfo, index) => {
                                    return <Select.Option key={index}
                                                          value={serverInfo.serverName}>{serverInfo.serverName}</Select.Option>
                                })
                            }
                        </Select>
                    </Col>
                    <Col key={3}>
                        选择日期:&nbsp;&nbsp;
                        <Select disabled={this.state.datesToSelect.length<=0} value={this.state.selectedDate}  style={{width: 120}} onChange={this.handleDateChange}>
                            {

                                this.state.datesToSelect?.map((date, index) => {
                                    return <Select.Option key={date + index} value={date}>{date}</Select.Option>
                                })
                            }
                        </Select>
                    </Col>
                </Row>
                <Row style={{marginTop: '1rem'}}>
                    <Col span={24} key={4}>
                        <Table
                            rowKey={(record) => record.Key}
                            pagination={this.state.pagination}
                               loading={this.state.isLoading}
                               columns={columns}
                               dataSource={data}
                               onChange={this.tableChance}/>
                    </Col>
                </Row>


            </div>
        );
    }
}

export default OwnerShipLog;