import React, {Component} from 'react';
import '../ruleContent.css'
class ProgramBlockRule extends Component {
    render() {
        return (
            <div>
                <h1>关于编程块脚本</h1>
                <div className={'textContent'}>
                    1、脚本不能用于战斗用途，比如找人（属于作弊手段）。
                    2、其他类型脚本性能受服务器监测，如果耗时超过100us会烧编程块。
                </div>

            </div>
        );
    }
}

export default ProgramBlockRule;