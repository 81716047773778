import React, {Component} from 'react';
import {Col, Row, Select} from "antd";
import TextArea from "antd/es/input/TextArea";
import Text from "antd/es/typography/Text";
import {sendCommand} from "../../../utils/urls";
import {postUrlEncoding} from "../../../utils/requests";

class CommandControl extends Component {
    state = {
        historyCommands:[],
        responseMessage: '',
        command:'',
        serverName:''
    }
    onChange = (event)=>{
        this.setState({
            command:event.target.value
        })
    }
    selectServer = (value)=>{


        this.setState({
            serverName:value
        })
    }
    sendCommand = (event) => {
        //取消换行
        event.preventDefault()
        this.state.historyCommands.push(this.state.command)

        postUrlEncoding(sendCommand,[['command',this.state.command],['serverName',this.state.serverName]])
            .then(resp => resp.json()).then(resp => {
            this.setState(
                {
                    responseMessage: resp.msg,
                    command:''
                }
            )
        })
    }
    componentDidMount() {
        const {serverInfos} = this.props
        this.setState({
            serverName:serverInfos[0]?.serverName
        })
    }

    render() {
        const {responseMessage,historyCommands,command} = this.state
        const {serverInfos} = this.props

        return (
            <div>
                <Row style={{marginTop: '4rem'}}>
                    <Col >
                        选择服务器:&nbsp;&nbsp;
                        <Select defaultValue={serverInfos[0]?.serverName} style={{ width: 120 }} onChange={this.selectServer}>
                            {
                                serverInfos.map((serverInfo,index)=>{
                                    return <Select.Option key={index} value={serverInfo.serverName}>{serverInfo.serverName}</Select.Option>
                                })
                            }
                        </Select>
                    </Col>
                    <Col offset={2} span={18}>
                        按下回车发送指令
                        <TextArea
                            allowClear
                            ref={"commandArea"}
                            rows={4}
                            placeholder="输入指令 最大长度100"
                            maxLength={100}
                            onPressEnter={this.sendCommand}
                            bordered={true}
                            value={command}
                            onChange={this.onChange}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={8}>
                        回显:<br/>
                        <Text>
                            <pre style={{textAlign:'left'}}>
                                {responseMessage}
                            </pre>

                        </Text>
                    </Col>
                    <Col  style={{textAlign:'left'}} offset={2} span={4}>
                        <button onClick={()=>this.setState({
                            historyCommands:[]
                        })}>清空历史</button>
                        <br/>
                        历史指令: <br/>
                        {
                            historyCommands.map((command,index)=>{
                                return <span key={command+''+index}>{command}<br/></span>
                            })
                        }
                    </Col>
                </Row>
            </div>
        );
    }
}

export default CommandControl;