import React, {Component} from 'react';
import {getAllServerInfos} from "../../../utils/urls";
import ServerInfo from "./ServerInfo";
import {Col, Row} from "antd";

import "./index.css"
import ComputerSystemInfo from "./ComputerSystemInfo";
class ServerMonitor extends Component {
    state = {
        serverInfos:[{
            serverName:'',
            onlinePlayers:[],
            simulationRatio:1.0}
        ]
    }
    componentDidMount() {
       this.setState({
           serverInfos:this.props.serverInfos
       })
    }


    render() {
        const {serverInfos}  = this.state
        return (
            <div className={"ServerMonitor"}>

                <Row style={{backgroundColor:'white'}}>
                    <Col span = {24}>
                    服务器监控设施
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <ComputerSystemInfo/>
                    </Col>
                </Row>
                <Row>
                    <Col span = {24}>
                        {
                            serverInfos.map((serverInfo,index)=>{
                                return  <ServerInfo key={index} {...serverInfo}/>
                            })
                        }
                    </Col>
                </Row>
            </div>
        );
    }
}

export default ServerMonitor;