import React, {Component} from 'react';
import { marked } from 'marked';

import './index.css'
import {getSelfCommandMd} from "../../../utils/urls";
import MdContent from "../../MdContent";

class SelfCommands extends Component {
  //   state = {
  //       mdStr:''
  //   }
  // async componentDidMount() {
  //   let resp =   await fetch(getMd, {
  //           method: "GET"
  //       })
  //
  //     const blob = await resp.blob()
  //     this.setState({
  //         mdStr:await blob.text()
  //     })
  //
  //   }
  //
  //   //https://github.com/i5ting/tocmd.npm
  //   //前往目标路径 然后 i5ting_toc -f sample.md -o
  //   render() {
  //       const parse = marked.parse(this.state.mdStr);
  //       // console.log(parse)
  //       return (
  //           // <div style={{height:'100%'} }>
  //
  //           <div className={'textContent'} dangerouslySetInnerHTML={{__html:parse}} >
  //
  //                                 {/*<iframe  style={{height:'100%'} } src={"../srcs/md.html"}/>*/}
  //               {/*<iframe  style={{height:'100%'} } src={"../srcs/preview/commands.html"}/>*/}
  //
  //           </div>
  //       );
  //   }



    render() {
        // console.log(parse)
        return (
            <MdContent mdUrl={getSelfCommandMd}/>
        );
    }
}
export default SelfCommands