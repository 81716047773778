import React, {Component} from 'react';
import "./index.css"
import Title from "antd/es/typography/Title";
import Paragraph from "antd/es/typography/Paragraph";
import Text from "antd/es/typography/Text";
import {webSocket} from "../../../utils/urls";

class ServerIntroduction extends Component {
    componentDidMount() {

    }

    render() {
        return (
            <div className={'text'}>
                <Title className = {'title'} level={1}>银河帝国服务器介绍</Title>
                <a name="top"/>
                <Paragraph className = {'Paragraph'}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    太空工程师-银河帝国服务器，最初开服时间可追溯到2019年4月份，截止至今已有长达三年的历程。服务器采用9900k
                    BGP高速线路托管在机房。服务器7*24小时开放。
                    服务器允许PVP/PVE，整合了许多模组供玩家游玩。服务器拥有一套自己的科技树系统，您可以在侧边栏的模组介绍中查看相关内容。
                    本服通过自主开发的插件提供大量特色指令和相关玩法，使您可以享受到独一无二的游玩体验
                    如 <Text strong> 船坞系统、远程仓库、模板系统、船只商店、玩家市场 收购/采购系统 海盗挑战 决斗系统 自动建造系统</Text>
                    未来还将开发更多的玩法和机制

                </Paragraph>


                {/* <a href="#top">到定位点</a>*/}


            </div>
        );
    }
}

export default ServerIntroduction;