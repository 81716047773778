import './App.css';
import Index from "./components/Index";
import BeiAn from "./components/BeiAn";
import store from "./store";
import React, {useEffect} from "react";
import {Provider as ReduxProvider} from "react-redux";
import {get} from "./utils/requests";
import {currentUser} from "./utils/urls";
import {BrowserRouter, Route, Switch} from "react-router-dom";

const modifyData = (newData) => {
    userData = newData
}
let userData = {
    username: null,
    steamId: null,
    modify: modifyData
}
export const {Provider, Consumer} = React.createContext(userData);

function App() {

    useEffect(() => {

        get(currentUser)
            .then(resp => resp.json())
            .then(resp => {
                if (resp.code === 200) {
                    userData.steamId = resp.data.steamId
                    userData.username = resp.data.username
                    userData.modify(userData)
                }
            })
    }, [])

    return (
        <ReduxProvider store={store}>
            <Provider value={userData}>
                <div style={{}} className="App">
                    <BrowserRouter>
                        <Switch>
                            <Route path={'*'}>
                                <Index/>
                            </Route>
                        </Switch>
                    </BrowserRouter>

                    <BeiAn/>
                </div>
            </Provider>
        </ReduxProvider>
    );
}

export default App;
