import React, {Component} from 'react';
import * as echarts from 'echarts';
import {systemMonitorSocket} from "../../../../utils/urls";
import {Col, Row} from "antd";

class ComputerSystemInfo extends Component {

    state = {
        cpusChart: null,
        netChart: null,
        webSocket: null,
        computerSystemMonitorVO: null
    }


    componentDidMount() {


        let webSocket1

        console.log('尝试连接服务器...')
        // eslint-disable-next-line react/no-direct-mutation-state
        webSocket1 = new WebSocket(systemMonitorSocket);


        webSocket1.onmessage = (messageEvent) => {
            console.log('on message')
            let message = messageEvent.data;
            const parse = JSON.parse(message);
            // eslint-disable-next-line react/no-direct-mutation-state
            this.state.computerSystemMonitorVO = parse

            this.changeOption()
            // // eslint-disable-next-line react/no-direct-mutation-state
            // this.setState({
            //     computerSystemMonitorVO:parse
            // })

        }
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.webSocket = webSocket1

        webSocket1.onopen = () => {
            const elementById = document.getElementById('cpuChartId')
            // eslint-disable-next-line react/no-direct-mutation-state
            this.state.cpusChart = echarts.init(elementById);
            console.log('连接成功')
        }
        webSocket1.onerror = (err) => {
            console.log("连接失败! ", err)
        }
        webSocket1.onclose = () => {
            console.log("连接断开!")
        }

    }

    changeOption = () => {
        const {computerSystemMonitorVO} = this.state
        if (computerSystemMonitorVO !== null) {
            const {cpuLoads, memoryUsed, totalMemory} = computerSystemMonitorVO
            //这里行列不需要名字 只需要设定一下有多少个即可
            const rows = []
            const cols = []
            if (cpuLoads != null && cpuLoads.length > 0) {
                for (let i = 0; i < cpuLoads.length; i++) {
                    rows.push(' ')
                }
                for (let i = 0; i < cpuLoads[0].length; i++) {
                    cols.push(' ')
                }
            }

            let data = []


            for (let i = 0; i < cpuLoads.length; i++) {
                for (let j = 0; j < cpuLoads[i].length; j++) {
                    data.push([j, i, Math.round(cpuLoads[i][j] * 100)])
                }
            }

            data = data.map(function (item) {
                return [item[0], item[1], item[2] || '-'];
            });


            const option = {
                tooltip: {
                    position: 'top'
                },
                grid: {
                    height: '50%',
                    top: '10%'
                },
                xAxis: {
                    type: 'category',
                    data: cols,
                    splitArea: {
                        show: true
                    }
                },
                yAxis: {
                    type: 'category',
                    data: rows,
                    splitArea: {
                        show: true
                    }
                },
                visualMap: {
                    min: 0,
                    max: 100,
                    calculable: false,
                    orient: 'horizontal',
                    left: 'center',
                    bottom: '0%'
                },
                series: [
                    {
                        name: 'cpu占用',
                        type: 'heatmap',
                        data: data,
                        label: {
                            show: true
                        },
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            };

            this.state.cpusChart?.setOption(option);
            this.setState({computerSystemMonitorVO})
        }
    }

    render() {


        let memoryUsed = 0, totalMemory = 0, uploadSpeed = '', downloadSpeed = ''
        const {computerSystemMonitorVO} = this.state
        if (computerSystemMonitorVO != null) {
            memoryUsed = computerSystemMonitorVO.memoryUsed / 1024 / 1024
            memoryUsed = Math.round(memoryUsed)
            totalMemory = computerSystemMonitorVO.totalMemory / 1024 / 1024
            totalMemory = Math.round(totalMemory)
            uploadSpeed = computerSystemMonitorVO.uploadSpeed
            downloadSpeed = computerSystemMonitorVO.downloadSpeed

        }

        return (
            <div>
                <Row>
                    <Col span={16}>
                        <Row>
                            <Col span={24}>
                                CPU使用监测
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} style={{height: '20rem'}}>
                                <div id='cpuChartId' style={{height: '100%', width: '100%'}}>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={8}>
                        <Row>
                            {`内存: ${memoryUsed}MB/${totalMemory}MB`}
                        </Row>
                        <Row>
                            {`上传: ${uploadSpeed}`}
                        </Row>
                        <Row>
                            {`下载: ${downloadSpeed}`}
                        </Row>
                    </Col>
                </Row>

            </div>

        );
    }
}

export default ComputerSystemInfo;